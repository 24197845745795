<template>
  <v-app>
    <v-app-bar
      elevation="0"
      app
      clipped-left
      style="border-bottom: 1px solid rgb(194, 194, 194) !important"
    >
      <v-app-bar-nav-icon>
        <div>
          <v-img :src="logo" width="39px" height="37px"></v-img>
        </div>
      </v-app-bar-nav-icon>
      <v-toolbar-title>{{ appName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        link
        :href="'https://help.generatortech.com.au/'"
        target="_blank"
      >
        <v-icon>mdi-book-open-blank-variant</v-icon>
      </v-btn>

      <v-btn icon link :href="'mailto:support@generatorapp.com.au'">
        <v-icon>mdi-help-circle</v-icon>
      </v-btn>

      <div max-width="150px">
        <v-menu max-width="150px">
          <template v-slot:activator="{ on }" max-width="150px">
            <v-list-item two-line v-on="on" max-width="150px">
              <v-list-item-avatar>
                <user-avatar size="30" :avatar="user.avatar" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ user.full_name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ user.email ? user.email : user.phone_number }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>

          <v-list>
            <v-list-item
              :to="{ name: 'user.details', params: { id: user.id } }"
            >
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logoutConfirmDialog = true">
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-if="permissions?.length > 0"
      v-model="controls.drawer"
      width="250px"
      clipped
      app
    >
      <v-layout column>
        <v-subheader>
          <div class="app-nav--title mx-auto">BUILDING</div>
        </v-subheader>
        <BuildingFilter class="app-filter" />
      </v-layout>

      <v-divider></v-divider>

      <v-list class="app-nav" flat>
        <v-list-item
          link
          active-class="white black--text"
          v-for="item in navItemsAvailables"
          :key="item.title"
          :to="item.to"
          :img="item.img"
          @click="resetStateQuery"
        >
          <v-list-item-icon>
            <v-icon v-text="item.img"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="pa-2 app-nav--item">
              {{ item.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          :value="false"
          prepend-icon="mdi-wrench"
          active-class="white black--text"
          v-if="maintenanceRequestAvailables.length > 0"
        >
          <template v-slot:activator>
            <v-list-item-title class="pa-2 app-nav--item">
              <div class="status-wrapper">
                <span>Maintenance</span>
                <div v-if="hasRequestUnread" class="unread"></div>
              </div>
            </v-list-item-title>
          </template>
          <v-list-item
            active-class="white black--text"
            v-for="item in maintenanceRequestAvailables"
            :key="item.title"
            :to="item.to"
            :img="item.img"
          >
            <v-list-item-title class="pa-2 app-nav--subitem">
              <div class="status-wrapper status-sub-item">
                <span>{{ item.label }}</span>
                <div
                  class="unread"
                  v-if="item.label === 'Request' && hasRequestUnread"
                ></div>
              </div>
            </v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group
          :value="false"
          prepend-icon="mdi-view-grid"
          active-class="white black--text"
          v-if="hubItemsAvailables.length > 0"
        >
          <template v-slot:activator>
            <v-list-item-title class="pa-2 app-nav--item"
              >Hub</v-list-item-title
            >
          </template>
          <v-list-item
            active-class="white black--text"
            v-for="item in hubItemsAvailables"
            :key="item.title"
            :to="item.to"
            :img="item.img"
            @click="resetStateQuery"
          >
            <v-list-item-title class="pa-2 app-nav--subitem">{{
              item.label
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item
          link
          active-class="white black--text"
          v-for="item in navItems2Availables"
          :key="item.title"
          :to="item.to"
          :img="item.img"
          @click="resetStateQuery"
        >
          <v-list-item-icon>
            <v-icon v-text="item.img"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="pa-2 app-nav--item">{{
              item.label
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          active-class="white black--text"
          v-for="item in buildingItemsAvailables"
          :key="item.title"
          :to="item.to"
          :img="item.img"
        >
          <v-list-item-icon>
            <v-icon v-text="item.img"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="pa-2 app-nav--item">{{
              item.label
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          :value="false"
          prepend-icon="mdi-cog"
          active-class="white black--text"
          v-if="
            maintenanceItemsAvailables.length +
              eventItemsAvailables.length +
              settingsItemsAvailables.length +
              pagesItemsAvailables.length >
              0 || hasPermissions(teams.requiredPermissions)
          "
        >
          <template v-slot:activator>
            <v-list-item-title class="pa-2 app-nav--item"
              >Settings</v-list-item-title
            >
          </template>

          <v-list-group
            no-action
            sub-group
            v-if="maintenanceItemsAvailables.length > 0"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="pa-2 app-nav--item"
                  >Maintenance</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="item in maintenanceItemsAvailables"
              :key="item.title"
              :to="item.to"
              active-class="white black--text"
              link
              @click="resetStateQuery"
            >
              <v-list-item-title
                class="pa-2 app-nav--item"
                v-text="item.label"
              ></v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group
            no-action
            sub-group
            v-if="eventItemsAvailables.length > 0"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="pa-2 app-nav--item"
                  >Events</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="item in eventItemsAvailables"
              :key="item.title"
              :to="item.to"
              active-class="white black--text"
              link
              @click="resetStateQuery"
            >
              <v-list-item-title
                class="pa-2 app-nav--item"
                v-text="item.label"
              ></v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group
            no-action
            sub-group
            v-if="pagesItemsAvailables.length > 0"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="pa-2 app-nav--item"
                  >Policies</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="item in pagesItemsAvailables"
              :key="item.title"
              :to="item.to"
              active-class="white black--text"
              link
            >
              <v-list-item-title
                class="pa-2 app-nav--item"
                v-text="item.label"
              ></v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-group
            no-action
            sub-group
            v-if="parcelItemsAvailables.length > 0"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="pa-2 app-nav--item"
                  >Parcels</v-list-item-title
                >
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="item in parcelItemsAvailables"
              :key="item.title"
              :to="item.to"
              active-class="white black--text"
              link
              @click="resetStateQuery"
            >
              <v-list-item-title
                class="pa-2 app-nav--item"
                v-text="item.label"
              ></v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item
            link
            active-class="white black--text"
            :key="teams.title"
            :to="teams.to"
            v-if="hasPermissions(teams.requiredPermissions)"
            @click="resetStateQuery"
          >
            <v-list-item-title class="pa-2 app-nav--subitem">{{
              teams.label
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            active-class="white black--text"
            :key="featuredProfileItems.title"
            :to="featuredProfileItems.to"
            v-if="hasPermissions(featuredProfileItems.requiredPermissions)"
          >
            <v-list-item-title class="pa-2 app-nav--subitem">{{
              featuredProfileItems.label
            }}</v-list-item-title>
          </v-list-item>

          <template v-if="isSupperAdmin">
            <v-list-item
              link
              active-class="white black--text"
              v-for="item in settingsItemsAvailables"
              :key="item.title"
              :to="item.to"
              @click="resetStateQuery"
            >
              <v-list-item-icon> </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="pa-2 app-nav--item">{{
                  item.label
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-main class="main-content">
      <div class="px-xl-12 px-lg-10 px-md-6 px-sm-4 px-xs-2 pb-5">
        <template v-if="!filter">
          <slot />
        </template>
        <Loading v-else :value="filter" />

        <Snackbar />
      </div>
    </v-main>
    <ConfirmModal
      v-model="logoutConfirmDialog"
      title="Logout"
      message="Are you sure you want to logout?"
    >
      <v-btn text @click="logoutConfirmDialog = false">Cancel</v-btn>
      <v-btn color="primary" text @click="logout">Logout</v-btn>
    </ConfirmModal>
  </v-app>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import UserAvatar from '@/components/UserAvatar'
import Snackbar from '@/components/global/Snackbar'
import BuildingFilter from '@/components/global/BuildingFilter'
import Loading from '@/components/common/Loading'
import { validatePermissions } from '@/utils/auth'
import ROLE from '@/utils/enums/Role'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'MainLayout',
  components: {
    UserAvatar,
    ConfirmModal,
    Snackbar,
    BuildingFilter,
    Loading,
  },
  mixins: [ControlsMixin],

  data() {
    return {
      navItems: [
        {
          label: 'Home',
          to: { name: 'home' },
          img: 'mdi-home-variant',
        },
        {
          label: 'Users',
          to: { name: 'users' },
          img: 'mdi-account',
          requiredPermissions: ['VIEW_USERS'],
        },
        {
          label: 'Notifications',
          to: { name: 'notifications' },
          img: 'mdi-bullhorn',
          requiredPermissions: ['VIEW_NOTIFICATIONS'],
        },
        {
          label: 'Services & Spaces',
          to: { name: 'bookables' },
          img: 'mdi-store',
          requiredPermissions: ['VIEW_BOOKABLES'],
        },
        {
          label: 'Parcels',
          to: { name: 'parcel.dashboard' },
          img: 'mdi-package',
          requiredPermissions: ['VIEW_PARCEL'],
        },
        {
          label: 'Booking Requests',
          to: { name: 'bookings' },
          img: 'mdi-message-text-fast',
          requiredPermissions: ['VIEW_BOOKINGS'],
        },
      ],
      maintenanceRequestItems: [
        {
          label: 'Requests',
          to: { name: 'maintenance.request' },
          img: 'mdi-wrench',
          requiredPermissions: ['VIEW_MAINTENANCES'],
        },
        {
          label: 'Assets',
          to: { name: 'maintenance.assets' },
          img: 'mdi-alpha-a-circle',
          requiredPermissions: ['VIEW_ASSETS'],
        },
        {
          label: 'Suppliers',
          to: { name: 'maintenance.suppliers' },
          img: 'mdi-account-hard-hat',
          requiredPermissions: ['VIEW_SUPPLIER'],
        },
        {
          label: 'Invoices',
          to: { name: 'maintenance.invoices' },
          img: 'mdi-invoice-fast',
          requiredPermissions: ['VIEW_INVOICE'],
        },
      ],
      navItems2: [
        {
          label: 'Perks',
          to: { name: 'perks' },
          img: 'mdi-tag-text',
          requiredPermissions: ['VIEW_PERKS'],
        },
        {
          label: 'Events',
          to: { name: 'event' },
          img: 'mdi-crown',
          requiredPermissions: ['VIEW_EVENTS'],
        },
        {
          label: 'News',
          to: { name: 'news' },
          img: 'mdi-newspaper-variant',
          requiredPermissions: ['VIEW_NEWS'],
        },
      ],
      hubItems: [
        {
          label: 'FAQs',
          to: { name: 'hub.faqs' },
          img: 'mdi-chat-question',
          requiredPermissions: ['VIEW_FAQS'],
        },
        {
          label: 'Contact Directory',
          to: { name: 'hub.contact-directory' },
          img: 'mdi-book-open-page-variant',
          requiredPermissions: ['VIEW_CONTACTS'],
        },
        {
          label: 'Gallery',
          to: { name: 'gallery' },
          img: 'mdi-newspaper-variant',
          requiredPermissions: ['VIEW_GALLERIES'],
        },
        {
          label: 'Documents',
          to: { name: 'hub.documents' },
          img: 'mdi-newspaper-variant',
          requiredPermissions: [],
        },
      ],
      buildingItems: [
        {
          label: 'Buildings',
          to: { name: 'buildings' },
          img: 'mdi-domain',
          requiredPermissions: ['VIEW_BUILDINGS'],
        },
      ],
      pagesItems: [
        {
          label: 'Privacy',
          to: { name: 'settings.pages.privacy' },
          img: 'mdi-circle-small',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
        {
          label: 'Terms',
          to: { name: 'settings.pages.terms-of-service' },
          img: 'mdi-circle-small',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
        {
          label: 'Version Control',
          to: { name: 'settings.version-control' },
          img: 'mdi-circle-small',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
      ],
      maintenanceItems: [
        {
          label: 'Urgency',
          to: { name: 'maintenance.urgencies' },
          img: 'mdi-circle-small',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
        {
          label: 'Categories',
          to: { name: 'maintenance.categories' },
          img: 'mdi-circle-small',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
        {
          label: 'Tags',
          to: { name: 'maintenance.tags' },
          img: 'mdi-circle-small',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
        {
          label: 'Entry Permissions',
          to: { name: 'maintenance.entry_permissions' },
          img: 'mdi-circle-small',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
        {
          label: 'Chart Of Accounts',
          to: { name: 'maintenance.chart_of_accounts' },
          img: 'mdi-circle-small',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
      ],
      parcelItems: [
        {
          label: 'Parcel Types',
          to: { name: 'parcel.parcel-types' },
          img: 'mdi-circle-small',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
        {
          label: 'Parcel Locations',
          to: { name: 'parcel.parcel-locations' },
          img: 'mdi-circle-small',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
      ],
      eventItems: [
        {
          label: 'Categories',
          to: { name: 'event.categories' },
          img: 'mdi-circle-small',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
      ],
      teams: {
        label: 'Team',
        to: { name: 'teams' },
        img: 'mdi-account',
        requiredPermissions: ['ASSIGN_ROLE'],
      },
      featuredProfileItems: {
        label: 'Feature Profile',
        to: { name: 'feature-profile' },
        img: 'mdi-circle-small',
        requiredPermissions: ['VIEW_FEATURE_PROFILE'],
      },
      logoutConfirmDialog: false,
      settingItems: [
        {
          label: 'Properties',
          to: { name: 'properties' },
          img: 'home-group',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
        {
          label: 'Lobby TV',
          to: { name: 'lobby-tvs' },
          img: 'mdi-chat-question',
          requiredPermissions: ['VIEW_SETTINGS'],
        },
        {
          label: 'Organisation',
          to: { name: 'settings.organisation' },
          requiredPermissions: ['VIEW_SETTINGS'],
        },
      ],
    }
  },

  created() {
    this.clearAllMaintenances()
    if (this.hasPermissionsRequest) {
      this.getMaintenances({ paginate: false })
    }
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      filter: (state) => state.filter.loading,
      permissions: (state) => state.auth.permissions,
      role: (state) => state.auth.role,
      allMaintenances: (state) => state.maintenance.allMaintenances,
    }),
    ...mapGetters({
      appName: 'appName',
      logo: 'logo',
      avatarThumb: 'auth/avatarThumb',
      loggedInUserId: 'auth/userId',
    }),
    navItemsAvailables() {
      return this.navItems.filter((item) => {
        return this.hasPermissions(item.requiredPermissions)
      })
    },
    maintenanceRequestAvailables() {
      return this.maintenanceRequestItems.filter((item) => {
        return this.hasPermissions(item.requiredPermissions)
      })
    },
    navItems2Availables() {
      return this.navItems2.filter((item) => {
        return this.hasPermissions(item.requiredPermissions)
      })
    },
    hubItemsAvailables() {
      return this.hubItems.filter((item) => {
        return this.hasPermissions(item.requiredPermissions)
      })
    },
    buildingItemsAvailables() {
      return this.buildingItems.filter((item) => {
        return this.hasPermissions(item.requiredPermissions)
      })
    },
    pagesItemsAvailables() {
      return this.pagesItems.filter((item) => {
        return this.hasPermissions(item.requiredPermissions)
      })
    },
    maintenanceItemsAvailables() {
      return this.maintenanceItems.filter((item) => {
        return this.hasPermissions(item.requiredPermissions)
      })
    },
    parcelItemsAvailables() {
      return this.parcelItems.filter((item) => {
        return this.hasPermissions(item.requiredPermissions)
      })
    },
    eventItemsAvailables() {
      return this.eventItems.filter((item) => {
        return this.hasPermissions(item.requiredPermissions)
      })
    },

    settingsItemsAvailables() {
      return this.settingItems.filter((item) => {
        return this.hasPermissions(item.requiredPermissions)
      })
    },

    isSupperAdmin() {
      return this.role === ROLE.SUPER_ADMIN
    },

    hasPermissionsRequest() {
      return validatePermissions(
        [PERMISSION.MAINTENANCES_VIEW],
        this.permissions
      )
    },

    hasRequestUnread() {
      return this.allMaintenances.some(
        (item) =>
          !item.last_comment_read && item.owner_id === this.loggedInUserId
      )
    },
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
      getMaintenances: 'maintenance/getMaintenances',
    }),

    ...mapMutations({
      setEventParams: 'event/setQueryParams',
      setViewType: 'event/setViewType',
      setUserParams: 'user/setQueryParams',
      setNotificationParams: 'notification/setQueryParams',
      setBookableParams: 'bookable/setQueryParams',
      setBookParams: 'booking/setQueryParams',
      setMaintenanceParams: 'maintenance/setQueryParams',
      setFaqParams: 'faq/setQueryParams',
      setGalleryParams: 'gallery/setQueryParams',
      setContactParams: 'contact/setQueryParams',
      setFolderParams: 'folder/setQueryParams',
      setBuildingParams: 'building/setQueryParams',
      setUrgencyParams: 'urgency/setQueryParams',
      setCategoryParams: 'category/setQueryParams',
      setLobbyParams: 'lobbyTv/setQueryParams',
      setListBookParams: 'bookable/setListBookParams',
      setSupplierParams: 'supplier/setQueryParams',
      setInvoiceParams: 'invoice/setQueryParams',
      setAssetParams: 'asset/setQueryParams',
      setParcelParams: 'parcel/setQueryParams',
      setParcelTypeParams: 'parcelType/setQueryParams',
      setParcelLocationParams: 'parcelLocation/setQueryParams',
      clearAllMaintenances: 'maintenance/clearAllMaintenances',
    }),

    hasPermissions(requiredPermissions = []) {
      return (
        requiredPermissions.length === 0 ||
        validatePermissions(requiredPermissions, this.permissions)
      )
    },

    resetStateQuery() {
      this.setEventParams(null)
      this.setUserParams(null)
      this.setNotificationParams(null)
      this.setBookableParams(null)
      this.setBookParams(null)
      this.setMaintenanceParams(null)
      this.setFaqParams(null)
      this.setContactParams(null)
      this.setFolderParams(null)
      this.setBuildingParams(null)
      this.setUrgencyParams(null)
      this.setCategoryParams(null)
      this.setLobbyParams(null)
      this.setViewType('list')
      this.setListBookParams(null)
      this.setGalleryParams(null)
      this.setSupplierParams(null)
      this.setInvoiceParams(null)
      this.setAssetParams(null)
      this.setParcelParams(null)
      this.setParcelTypeParams(null)
      this.setParcelLocationParams(null)
    },
  },
}
</script>
<style lang="scss" scoped>
.app-filter {
  width: 100%;
  padding: 0 24px;
}

.app-nav--subitem {
  margin-left: 55px !important;
}

.v-navigation-drawer {
  height: 100dvh !important;
}

.status-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.unread {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--v-primary-base);
}

.status-sub-item {
  padding-right: 27px;
}

::v-deep
  .v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 0px;
  margin-left: 16px;
}
</style>
